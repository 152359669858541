module.exports = {
	"treeMenu": [
		{
			"label": "Modules",
			"icon": "el-icon-pi-modules",
			"index": "/program",
			"isVisibleInGeneric": false,
			"permissions": "client_TEACHER|client_ADMIN|client_OWNER|client_CERTIFIER|grains",
			"class": "border-top",
			"children": [
				{
					"label": "Détails",
					"inSidebar": false,
					"route": {
						"name": "ProgramDetails"
					}
				}
			]
		},
		{
			"label": "Environnements",
			"icon": "el-icon-pi-context",
			"index": "/context",
			"permissions": "APP_superadmin",
			"class": "border-top"
		},
		{
			"label": "Grains",
			"icon": "el-icon-pi-briques",
			"index": "/course-blocks",
			"permissions": "nobody",
			"class": "border-top",
			"children": [
				{
					"label": "Édition",
					"inSidebar": false,
					"route": {
						"name": "CourseBlockEdit"
					}
				},
				{
					"label": "Création",
					"inSidebar": false,
					"route": {
						"name": "CourseBlockCreate"
					}
				}
			]
		},
		{
			"label": "Séquences",
			"icon": "el-icon-pi-parcours",
			"index": "/course",
			"permissions": "nobody",
			"children": [
				{
					"label": false,
					"inSidebar": false,
					"route": {
						"name": "CourseEdit"
					}
				},
				{
					"label": "Création",
					"inSidebar": false,
					"route": {
						"name": "CourseCreate"
					}
				}
			]
		},
		{
			"label": "Types de séquences",
			"icon": "el-icon-pi-parcours",
			"index": "/course-type",
			"permissions": "nobody",
			"children": [
				{
					"label": false,
					"inSidebar": false,
					"route": {
						"name": "TypeEdit"
					}
				},
				{
					"label": "Création",
					"inSidebar": false,
					"route": {
						"name": "TypeCreate"
					}
				}
			]
		},
		{
			"label": "Modules",
			"icon": "el-icon-pi-produits",
			"index": "/program",
			"permissions": "nobody",
			"children": [
				{
					"label": "Édition",
					"inSidebar": false,
					"route": {
						"name": "ProgramEdit"
					}
				},
				{
					"label": "Création",
					"inSidebar": false,
					"route": {
						"name": "ProgramCreate"
					}
				}
			]
		},
		{
			"label": "Glossaire",
			"icon": "el-icon-pi-glossaire",
			"index": "/course-terms",
			"permissions": "nobody",
			"children": [
				{
					"label": "Édition",
					"inSidebar": false,
					"route": {
						"name": "TermEdit"
					}
				},
				{
					"label": "Création",
					"inSidebar": false,
					"route": {
						"name": "TermCreate"
					}
				}
			]
		},
		{
			"label": "Entreprises",
			"icon": "el-icon-pi-entreprise",
			"index": "/company",
			"permissions": "grains",
			"disabled": "generic",
			"isVisibleInGeneric": false,
			"class": "border-top",
			"children": [
				{
					"label": "Édition",
					"disabled": "generic",
					"inSidebar": false,
					"route": {
						"name": "CompanyEdit"
					}
				},
				{
					"label": "Création",
					"disabled": "generic",
					"inSidebar": false,
					"route": {
						"name": "CompanyCreate"
					}
				}
			]
		},
		{
			"label": "Utilisateurs",
			"icon": "el-icon-pi-participants",
			"index": "/users",
			"permissions": "grains",
			"disabled": "generic",
			"isVisibleInGeneric": false,
			"children": [
				{
					"label": "Édition",
					"inSidebar": false,
					"disabled": "generic",
					"route": {
						"name": "UserEdit"
					}
				},
				{
					"label": "Création",
					"inSidebar": false,
					"disabled": "generic",
					"route": {
						"name": "UserCreate"
					}
				}
			]
		},
		{
			"label": "Thèmes & Compétences",
			"icon": "el-icon-pi-metiers-competences",
			"inBreadcrumb": false,
			"index": "/#job-competencies",
			"permissions": "grains|context_CONTRIBUTOR_{{contextId}}",
			"children": [
				{
					"label": "Thèmes",
					"index": "/job",
					"permissions": "grains|context_CONTRIBUTOR_{{contextId}}",
					"children": [
						{
							"label": "Édition",
							"inSidebar": false,
							"route": {
								"name": "JobEdit"
							}
						},
						{
							"label": "Création",
							"inSidebar": false,
							"route": {
								"name": "JobCreate"
							}
						}
					]
				},
				{
					"label": "Compétences",
					"index": "/competency",
					"permissions": "grains|context_CONTRIBUTOR_{{contextId}}",
					"children": [
						{
							"label": "Édition",
							"inSidebar": false,
							"route": {
								"name": "CompetencyEdit"
							}
						},
						{
							"label": "Création",
							"inSidebar": false,
							"route": {
								"name": "CompetencyCreate"
							}
						}
					]
				}
			]
		},
		{
			"label": "Offres",
			"icon": "el-icon-pi-CREDITS",
			"inBreadcrumb": false,
			"index": "/#products",
			"permissions": "grains",
			"isVisibleInGeneric": false,
			"children": [
				{
					"label": "Crédits",
					"index": "/products",
					"permissions": "grains",
					"children": [
						{
							"label": "Création",
							"inSidebar": false,
							"route": {
								"name": "ProductCreate"
							}
						}
					]
				},
				{
					"label": "Licences",
					"index": "/products/license",
					"permission": "grains",
					"children": [
						{
							"label": "Création",
							"inSidebar": false,
							"route": {
								"name": "LicensesProductCreate"
							}
						}
					]
				}
			]
		},
		{
			"label": [
				"models.Certification.title",
				2
			],
			"icon": "el-icon-pi-certifications",
			"index": "/class/certification",
			"permissions": "client_CERTIFIER&context_allowAccessToCertificationFeature|grains&context_allowAccessToCertificationFeature",
			"disabled": "generic",
			"children": [
				{
					"label": "Création",
					"inSidebar": false,
					"route": {
						"name": "ClassCreate"
					}
				}
			]
		},
		{
			"label": [
				"navigation.StudentAndGroup",
				2
			],
			"icon": "el-icon-pi-participants",
			"index": "/students",
			"permissions": "client"
		},
		{
			"label": "Animateurs",
			"icon": "el-icon-pi-animateurs",
			"index": "/teachers",
			"permissions": "client_TEACHER|client_ADMIN|client_OWNER|client_SUPERVISOR",
			"children": [
				{
					"label": "Animateurs",
					"index": "/teachers",
					"permissions": "client_TEACHER|client_ADMIN|client_OWNER"
				},
				{
					"label": "Observateurs",
					"index": "/supervisors",
					"permissions": "client_SUPERVISOR|client_TEACHER|client_ADMIN|client_OWNER"
				}
			]
		},
		{
			"label": [
				"roles.CERTIFIER.title",
				2
			],
			"icon": "el-icon-pi-certificateurs",
			"index": "/certifiers",
			"permissions": "client_CERTIFIER&context_allowAccessToCertificationFeature|client_ADMIN&context_allowAccessToCertificationFeature|client_OWNER&context_allowAccessToCertificationFeature"
		},
		{
			"label": "Administrateurs",
			"icon": "el-icon-pi-administrateurs",
			"index": "/admin",
			"permissions": "client_ADMIN|client_OWNER"
		},
		{
			"label": "Mon entreprise",
			"icon": "el-icon-pi-entreprise",
			"index": "/company/current",
			"permissions": "client_ADMIN|client_OWNER"
		},
		{
			"label": "Aide et informations",
			"icon": "el-icon-pi-help",
			"index": "/aide",
			"class": "border-top",
			"disabled": "generic",
			"isVisibleInGeneric": false,
			"inBreadcrumb": false,
			"children": [
				{
					"label": "Aide",
					"index": "/aide"
				},
				{
					"label": "CGVU & Mentions Légales",
					"index": "/legal/mentions"
				},
				{
					"label": "Protection des données",
					"index": "/legal/protection-des-donnees"
				}
			]
		},
		{
			"label": "Commentaires",
			"icon": "el-icon-fa-pie-chart",
			"isVisibleInGeneric": false,
			"index": "/statistics/programs/reviews",
			"permissions": "grains|context_CONTRIBUTOR_{{contextId}}"
		}
	]
};